import React from "react";
import Layout from "./src/layouts/layout";
import { Global } from "@emotion/react";

const WrappedLayout = ({ element, props }) => {
  return (
    <Layout {...props}>
      <Global
        styles={{
          body: {
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
          },
        }}
      />
      {element}
    </Layout>
  );
};

export default WrappedLayout;
