import React, { Fragment, useEffect, useState } from "react";
import { Link as pageLink } from "gatsby";
import { Flex, Text, TextLink } from "@indeed/ifl-components";
import { NAV_LINKS } from "globals/navLinks";
import { useLocationData } from "hooks/useLocationData";
import { DropdownButton } from "./dropdownButton";
import { motion, AnimatePresence } from "framer-motion";

export const DesktopNav: React.FC = () => {
  const { key: currentPageKey, type: currentPageType } = useLocationData();
  const isCurrentPageTertiary = currentPageType === "tertiary";
  const brandGuidelineSection =
    isCurrentPageTertiary || currentPageKey === "brandParent";
  const [isNavExpanded, setIsNavExpanded] = useState(brandGuidelineSection);

  useEffect(() => {
    // Brand and any tertiary pages (children of brand) causes nav to be expanded on load
    setIsNavExpanded(brandGuidelineSection);
  }, [brandGuidelineSection]);

  const brandLinks = NAV_LINKS.find(
    (link) => link.key === "brandParent"
  )!.children;

  // add a hidden pseudo element with the width of text in fontWeight 600 so the layout doesn't shift on hover
  const pseudoHoverEffect = {
    ":after": {
      content: "attr(data-text)",
      height: 0,
      visibility: "hidden",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      userSelect: "none",
      pointerEvents: "none",
      fontWeight: 600,
    },
  };

  return (
    <Flex
      sx={{
        display: ["none", null, null, "flex"],
        position: "fixed",
        width: "100%",
        top: 0,
        zIndex: 100,
        flexDirection: "column",
      }}
    >
      <Flex
        sx={{
          height: "60px",
          width: "100%",
          px: "10vw",
          alignItems: "center",
          backgroundColor: "rgba(255,255,255,.85)",
        }}
      >
        {NAV_LINKS.map(({ name, children, type, key, to }) => {
          const isPrimary = type === "primary";
          // If on the tertiary brand page, brand gets a "selected styling"
          const isSelected =
            key === currentPageKey ||
            (isCurrentPageTertiary && key === "brandParent");
          const color = !isPrimary && isSelected ? "blue.800" : "neutral.1000";

          const linkStyle = {
            fontSize: isPrimary ? "20px" : "18px",
            fontWeight: isPrimary || isSelected ? 600 : 400,
            mr: children ? "0px" : "40px",
            color,
            transition: "all 500ms ease",
            borderColor: "transparent",
            ":visited": { color, borderColor: "transparent !important" },
            ":focus": { borderColor: "transparent !important" },
            ":hover": {
              color: "blue.800",
              fontWeight: 600,
            },
          };

          return type === "external" ? (
            <TextLink
              data-text={name}
              aria-label={name}
              key={key}
              as="a"
              href={to}
              target="_blank"
              sx={{ ...linkStyle, ...pseudoHoverEffect }}
            >
              {name}
            </TextLink>
          ) : (
            <Fragment key={key}>
              {to && (
                <TextLink
                  data-text={name}
                  aria-label={name}
                  sx={{ ...linkStyle, ...pseudoHoverEffect }}
                  as={pageLink}
                  to={to}
                >
                  {name}
                </TextLink>
              )}
              {children && (
                <DropdownButton
                  sx={{
                    ...linkStyle,
                    ":hover": { span: { fontWeight: 600, color: "blue.800" } },
                    mr: "40px",
                    p: 0,
                  }}
                  expanded={isNavExpanded}
                  onClick={() => setIsNavExpanded(!isNavExpanded)}
                >
                  <Text
                    data-text={name}
                    aria-label={name}
                    sx={{ ...linkStyle, ...pseudoHoverEffect }}
                  >
                    {name}
                  </Text>
                </DropdownButton>
              )}
            </Fragment>
          );
        })}
      </Flex>
      <AnimatePresence initial={false}>
        {isNavExpanded && (
          <motion.div
            key="brandGuidelineItems"
            initial={{ opacity: 0, height: 0 }}
            animate={{
              height: 50,
              opacity: 0.85,
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            }}
            style={{
              width: "100%",
              backgroundColor: "#fff",
              padding: "0 10vw",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Flex>
              {brandLinks?.map(({ name, shortenedName, key, to }) => {
                const isSelected = key === currentPageKey;
                return (
                  <TextLink
                    key={key}
                    sx={{
                      borderColor: "transparent",
                      fontSize: "14px",
                      mr: "14px",
                      color: isSelected ? "blue.800" : "neutral.1000",
                      transition: "all 500ms ease",
                      fontWeight: isSelected ? 600 : 400,
                      ":visited": {
                        borderColor: "transparent",
                        color: isSelected ? "blue.800" : "neutral.1000",
                      },
                      ":focus": { borderColor: "transparent !important" },
                      ":hover": { color: "blue.800", fontWeight: 600 },
                      ":active": { borderColor: "transparent" },
                      ...pseudoHoverEffect,
                    }}
                    as={pageLink}
                    to={to}
                    data-text={shortenedName || name}
                    aria-label={shortenedName || name}
                  >
                    {shortenedName || name}
                  </TextLink>
                );
              })}
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
};
