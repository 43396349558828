import type { OrphanedLink } from "types/globals";

export const ORPHANED_LINKS = [
  {
    key: "404",
    name: "404",
    to: "/404",
    type: "orphaned",
  },
  {
    key: "404.html",
    name: "404",
    to: "/404.html",
    type: "orphaned",
  },
  {
    key: "signup",
    name: "Signup",
    to: "/signup",
    type: "orphaned",
  },
  {
    key: "success",
    name: "Success",
    to: "/success",
    type: "orphaned",
  },
  {
    key: "confirm",
    name: "Confirm",
    to: "/confirm",
    type: "orphaned",
  },
  {
    key: "confirmation",
    name: "Confirmation",
    to: "/confirmation",
    type: "orphaned",
  },
] as OrphanedLink[];
