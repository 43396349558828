import React from "react";
import { Link as pageLink } from "gatsby";
import { ButtonLink, Flex } from "@indeed/ifl-components";
import ArrowLeft from "@indeed/ifl-icons/ArrowLeft";
import ArrowRight from "@indeed/ifl-icons/ArrowRight";
import { NavLink } from "types/globals";
import { SystemStyleObject } from "@indeed/ifl-css";

interface PaginationProps {
  previous: NavLink;
  next: NavLink;
  previousLabel?: string;
  nextLabel?: string;
  sx?: SystemStyleObject;
}

export const Pagination: React.FC<PaginationProps> = ({
  previous,
  next,
  previousLabel,
  nextLabel,
  sx,
}) => {
  if (!previous?.to || !next?.to) {
    return null;
  }
  return (
    <Flex sx={{ p: "40px 10vw", justifyContent: "space-between", ...sx }}>
      <ButtonLink
        as={pageLink}
        to={previous.to}
        variant="text"
        iconBefore={<ArrowLeft />}
      >
        {previousLabel
          ? previousLabel
          : previous.shortenedName || previous.name}
      </ButtonLink>
      <ButtonLink
        as={pageLink}
        to={next.to}
        variant="text"
        iconAfter={<ArrowRight />}
      >
        {nextLabel ? nextLabel : next.shortenedName || next.name}
      </ButtonLink>
    </Flex>
  );
};
