import { aurora } from "@indeed/ifl-themes";
import { expressive } from "@indeed/ifl-tokens";

export const indeeddotdesignTheme = {
  ...aurora,
  breakpoints: ["576px", "768px", "992px", "1200px"],
  // breakpoints: ["360px", "375px", "768px", "1024px"],
  colors: {
    ...aurora.colors,
    ...expressive,
    primary: {
      ...aurora.colors.primary,
      1100: "#001c40",
    },
  },
  fonts: {
    ...aurora.fonts,
    default:
      '"Indeed Sans", "Helvetica Neue", "Helvetica", "Arial", "Liberation Sans", "Roboto", "Noto", sans-serif',
  },
  fontSizes: {
    ...aurora.fontSizes,
    9: "4.5rem",
    10: "5.75rem",
  },
  shadows: {
    ...aurora.shadows,
    brandcustom: "0px 4px 16px rgba(0, 0, 0, 0.15)",
  },
  text: {
    ...aurora.text,
    levelmedium: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
    },
    levelrgular: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
    },
    levelheadline: {
      fontFamily: ["Indeed Sans", null, null, "Indeed Sans"],
      fontWeight: "normal",
      lineHeight: "tighter",
      fontSize: [7, null, 9, 10],
    },
    levelsubheadline: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [4, null, 5, 6],
    },
    levelsectionheader: {
      fontFamily: "Indeed Sans",
      lineHeight: "tight",
      fontSize: [6, null, 7, 7],
    },
    levelsectionsubheader: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [4, null, 6, 6],
    },
    levelarticletitle: {
      fontFamily: "Indeed Sans",
      lineHeight: ["normal", null, "tight", "tight"],
      fontSize: [4, null, 6, 6],
    },
    levelarticletitlehighlight: {
      fontFamily: "Indeed Sans",
      lineHeight: ["normal", null, "tight", "tight"],
      fontSize: [5, null, 7, 7],
    },
    levelarticlecontent: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [3, null, 4, 4],
    },
    levelarticlecontenthighlight: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [5, null, 6, 6],
    },
    levelarticleauthor: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [2, null, 3, 3],
    },
    levelarticleauthortitle: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [2, null, 3, 3],
    },
    levelcardtitle: {
      fontFamily: "Indeed Sans",
      lineHeight: "normal",
      fontSize: [3, null, null, 4],
    },
    levelcardcontent: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [3, null, null, 4],
    },
    levelnavigation: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [3, null, 4, null, 6],
    },
    leveltabtitle: {
      fontFamily: ["Indeed Sans", null, null, "Indeed Sans"],
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: [3, null, 4, 4],
    },
    levelimagetagline: {
      fontFamily: "Indeed Sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: 3,
      color: "neutral.700",
    },
  },
  space: {
    ...aurora.space,
    10: "96px",
    11: "128px",
    12: "160px",
    13: "216px",
  },
};
