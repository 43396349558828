import { useLocation } from "@reach/router";
import { NAV_LINKS } from "globals/navLinks";
import { ORPHANED_LINKS } from "globals/orphanedLinks";
import { NavLink, OrphanedLink } from "types/globals";

const getLastPartOfUrl = (url: string) =>
  url.trim().replace(/\/$/, "").split("/").pop() || "";

const searchLinks = (navLinks: NavLink[], key: string): NavLink => {
  for (let i = 0; i < navLinks.length; i++) {
    const navLink = navLinks[i];
    if (navLink.key === key) {
      const previous =
        i - 1 > -1 ? navLinks[i - 1] : navLinks[navLinks.length - 1];
      const next = i + 1 < navLinks.length ? navLinks[i + 1] : navLinks[0];
      return { ...navLink, previous, next };
    }
    if (navLink.children) {
      return searchLinks(navLink.children, key);
    }
  }
  console.error(
    `Navigation data for key ${key} could not be found. Is the URL in globals/navLinks.ts?`
  );
  return { key: "", name: "", to: "", type: "secondary" };
};

// Pulls the location of the browser and returns the matching navLink data object
export const useLocationData = (): NavLink | OrphanedLink => {
  const { pathname } = useLocation();
  const key = getLastPartOfUrl(pathname);
  if (pathname.includes("/article/")) {
    return {
      key: "article",
      name: "Article",
      to: pathname,
      type: "secondary",
    };
  }

  const orphanedLink = ORPHANED_LINKS.find((link) => link.key === key);

  if (orphanedLink) {
    return orphanedLink;
  }

  if (key === "") {
    return searchLinks(NAV_LINKS, "home");
  }
  return searchLinks(NAV_LINKS, key);
};
