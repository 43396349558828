export const getIconColorStyles = (
  color: string,
  interactiveColor?: string
) => {
  return {
    color,
    borderColor: "transparent",
    ":visited": { color },
    ":focus": { borderColor: "transparent !important" },
    ":hover": {
      color: `${interactiveColor || color} !important`,
      backgroundColor: "transparent !important",
      borderColor: "transparent !important",
    },
    ":active": {
      color: `${interactiveColor || color} !important`,
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  };
};
