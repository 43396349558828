// todo: this is mostly copied from old component.

import React from "react";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import { Box, Icon, Text, TextLink } from "@indeed/ifl-components";
import LinkedIn from "@indeed/ifl-icons/LinkedIn";
import Twitter from "@indeed/ifl-icons/Twitter";
import Instagram from "@indeed/ifl-icons/Instagram";
import { Medium } from "static/icons/medium";
import { CPRA } from "static/icons/cpra";
import { SocialButton } from "components/socialButton";

const Footer = () => {
  const { siteFooterMenu, indeedFooterMenu, impressumMenu, privacyMenu } =
    useStaticQuery(graphql`
      {
        siteFooterMenu: wpMenu(slug: { eq: "site-footer" }) {
          menuItems {
            nodes {
              label
              path
            }
          }
        }
        indeedFooterMenu: wpMenu(slug: { eq: "indeed-footer" }) {
          menuItems {
            nodes {
              label
              path
            }
          }
        }
        impressumMenu: wpMenu(slug: { eq: "impressum" }) {
          menuItems {
            nodes {
              label
              path
            }
          }
        }
        privacyMenu: wpMenu(slug: { eq: "privacy-links" }) {
          menuItems {
            nodes {
              label
              path
            }
          }
        }
      }
    `);

  const socialLinks = {
    twitter: "https://mobile.twitter.com/designindeed",
    instagram: "https://www.instagram.com/indeeddesign",
    linkedin: "https://www.linkedin.com/showcase/indeed-design",
    medium: "https://medium.com/indeed-design",
  };

  const copyrightYear = new Date().getFullYear();

  const footerMenuStyle = {
    pl: "0",
    li: {
      listStyle: "none",
    },
  };

  const footerLinkStyle = {
    a: {
      lineHeight: "1.8em",
      textDecoration: "none",
      minHeight: ["48px", null, null, "initial"],
      minWidth: ["48px", null, null, "initial"],
      display: "block",
    },
  };

  const footerGrid = {
    gridTemplateAreas: `
    "left center right"
    `,
    gridTemplateColumns: "2fr 1fr 1fr",
  };

  return (
    <Box
      as="footer"
      sx={{
        px: "10vw",
        mx: [null, null, "auto"],
      }}
    >
      <Box sx={{ display: ["block", null, null, "grid"], ...footerGrid }}>
        <Box sx={{ gridArea: "left" }}>
          <Text
            level={"sectionsubheader"}
            as="p"
            sx={{ color: "neutral.1000", mb: [3, 6] }}
          >
            Indeed Design
          </Text>
          <SocialButton href={socialLinks.twitter} label="Twitter">
            <Twitter />
          </SocialButton>
          <SocialButton href={socialLinks.instagram} label="Instagram">
            <Instagram />
          </SocialButton>
          <SocialButton href={socialLinks.linkedin} label="LinkedIn">
            <LinkedIn />
          </SocialButton>
          <SocialButton href={socialLinks.medium} label="Medium">
            <Icon>
              <Medium />
            </Icon>
          </SocialButton>
        </Box>
        {siteFooterMenu.menuItems.nodes.length > 0 ? (
          <Box
            as="ul"
            sx={{
              gridArea: "center",
              mt: 6,
              fontSize: [3, null, 3, 4],
              ...footerMenuStyle,
              ...footerLinkStyle,
            }}
          >
            {siteFooterMenu.menuItems.nodes.map((link) => (
              <Box as="li" key={link.label}>
                <Box
                  as={GatsbyLink}
                  level="cardcontent"
                  to={link.path}
                  sx={{
                    color: "neutral.1000",
                    fontFamily: "Indeed Sans",
                    textDecoration: "none",
                    transition:
                      "color 200ms cubic-bezier(0.645, 0.045, 0.355, 1)",
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.900",
                    },
                  }}
                >
                  {link.label}
                </Box>
              </Box>
            ))}
          </Box>
        ) : null}
        {indeedFooterMenu.menuItems.nodes.length > 0 ? (
          <Box
            as="ul"
            sx={{
              gridArea: "right",
              mt: 6,
              fontSize: [3, null, null, 4],
              mb: 7,
              ...footerMenuStyle,
              ...footerLinkStyle,
            }}
          >
            {indeedFooterMenu.menuItems.nodes.map((link) => (
              <Box as="li" key={link.label}>
                <Box
                  as="a"
                  level={3}
                  target="_blank"
                  href={link.path}
                  sx={{
                    color: "neutral.1000",
                    fontFamily: "Indeed Sans",
                    textDecoration: "none",
                    transition:
                      "color 200ms cubic-bezier(0.645, 0.045, 0.355, 1)",
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.900",
                    },
                  }}
                >
                  {link.label}
                </Box>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
      <Box
        as="hr"
        sx={{
          bg: "neutral.500",
          border: 0,
          height: 1,
          my: 2,
          mt: [null, null, "6em", "10em"],
          display: ["none", null, "block"],
        }}
      />
      <Box
        sx={{ display: ["block", null, null, "grid"], mb: 8, ...footerGrid }}
      >
        <Text
          as="span"
          level={3}
          sx={{ gridArea: "left", color: "neutral.700" }}
        >
          © {copyrightYear} Indeed
        </Text>
        {privacyMenu.menuItems.nodes.length > 0 ? (
          <Box
            as="ul"
            sx={{
              gridArea: [null, null, "center"],
              display: ["block", null, null],
              ...footerMenuStyle,
              ...footerLinkStyle,
            }}
          >
            {privacyMenu.menuItems.nodes.map((link) => (
              <Text as="li" level={3} key={link.label}>
                <TextLink
                  href={link.path}
                  target="_blank"
                  sx={{ color: "neutral.700", border: "none" }}
                >
                  {link.label} <CPRA />
                </TextLink>
              </Text>
            ))}
          </Box>
        ) : null}
        {impressumMenu.menuItems.nodes.length > 0 ? (
          <Box
            as="ul"
            sx={{ gridArea: "right", ...footerMenuStyle, ...footerLinkStyle }}
          >
            {impressumMenu.menuItems.nodes.map((link) => (
              <Text as="li" level={3} key={link.label}>
                <TextLink
                  href={link.path}
                  target="_blank"
                  sx={{ color: "neutral.700", border: "none" }}
                >
                  {link.label}
                </TextLink>
              </Text>
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Footer;
