// todo: this is mostly copied from old component

import React from "react";
import {
  ButtonLink,
  ButtonLinkProps,
  VisuallyHidden,
} from "@indeed/ifl-components";
import type { SystemStyleObject } from "@indeed/ifl-css";

interface SocialButtonProps extends ButtonLinkProps {
  children?: React.ReactNode;
  href: string;
  label: string;
  sx?: SystemStyleObject;
}

export const SocialButton: React.FC<SocialButtonProps> = ({
  children,
  sx,
  href,
  label,
  ...props
}) => {
  return (
    <ButtonLink
      variant="tertiary"
      size="sm"
      target="_blank"
      href={href}
      iconOnly
      {...props}
      sx={{
        borderRadius: "100%",
        "&:hover": {
          color: "neutral.1000",
        },
        ...sx,
      }}
    >
      {children}
      <VisuallyHidden>{label}</VisuallyHidden>
    </ButtonLink>
  );
};
