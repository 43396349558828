import React, { ReactNode } from "react";
import { Button } from "@indeed/ifl-components";
import ChevronDown from "@indeed/ifl-icons/ChevronDown";
import type { SystemStyleObject } from "@indeed/ifl-css";

export interface DropdownButtonProps {
  children: ReactNode;
  expanded?: boolean;
  onClick?: Function;
  sx?: SystemStyleObject;
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  children,
  expanded = false,
  onClick,
  sx,
}) => {
  const onButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      aria-expanded={expanded.toString()}
      size="md"
      variant="inverse"
      onClick={onButtonClick}
      iconAfter={
        <ChevronDown
          sx={{
            transition: "transform 500ms",
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
          }}
          aria-hidden="true"
          fill="neutral.1000"
        />
      }
      sx={{
        ":hover:not([aria-disabled='true'])": {
          backgroundColor: "transparent",
          "> svg": {
            fill: "blue.800",
          },
        },
        ":active:not([aria-disabled='true'])": {
          boxShadow: "none",
          backgroundColor: "transparent",
        },
        ":focus:after": {
          boxShadow: "0 0 0 .0625rem rgb(37, 87, 167)",
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
