import React, { useEffect, useState } from "react";
import { Link as pageLink } from "gatsby";
import { CloseButton, Drawer, Target, Trigger } from "@accessible/drawer";
import { Box, Flex, Button, TextLink } from "@indeed/ifl-components";
import { colors, expressive } from "@indeed/ifl-tokens";
import Close from "@indeed/ifl-icons/Close";
import Hamburger from "@indeed/ifl-icons/Hamburger";
import { useLocationData } from "hooks/useLocationData";
import { NAV_LINKS } from "globals/navLinks";
import { getIconColorStyles } from "helpers/getIconColorStyles";
import { DropdownButton } from "./dropdownButton";

const HomePageLink = () => (
  <TextLink
    as={pageLink}
    to="/"
    sx={{
      fontSize: "20px",
      fontWeight: 600,
      ...getIconColorStyles(colors.neutral["1000"]),
    }}
  >
    Indeed Design
  </TextLink>
);

export const MobileNav: React.FC = () => {
  const { key: currentPageKey, type: currentPageType } = useLocationData();
  const isCurrentPageTertiary = currentPageType === "tertiary";
  const [areLinksExpanded, setAreLinksExpanded] = useState(false);

  useEffect(() => {
    setAreLinksExpanded(
      isCurrentPageTertiary || currentPageKey === "brandParent"
    );
  }, [currentPageKey, isCurrentPageTertiary]);

  const headerColor = colors.neutral["1000"];

  const createLinkStyle = (key: string) => {
    const isSelected =
      (isCurrentPageTertiary && key === "brandParent") ||
      key === currentPageKey;
    const color = isSelected ? expressive.blue["800"] : colors.neutral["1000"];
    return {
      fontSize: "18px",
      fontWeight: isSelected ? 600 : 400,
      color: `${color} !important`,
      transition: "all 500ms ease",
      borderColor: "transparent",
      backgroundColor: "transparent !important",
      minHeight: "48px",
      ":visited": { color },
      ":hover": {
        color: `${expressive.blue["800"]} !important`,
        fontWeight: 600,
      },
      ":focus": { borderColor: "transparent !important" },
    };
  };

  return (
    <Box sx={{ display: ["block", null, null, "none"] }}>
      <Drawer>
        <Flex
          sx={{
            position: "fixed",
            width: "100%",
            top: 0,
            zIndex: 100,
            justifyContent: "space-between",
            height: "60px",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,.85)",
            transition: "background-color 500ms ease",
            px: "10vw",
          }}
        >
          <HomePageLink />
          <Trigger>
            <Button
              aria-label="Open navigation drawer"
              size="md"
              variant="inverse"
              iconOnly
              sx={{
                color: headerColor,
                ":hover": {
                  color: `${headerColor} !important`,
                  backgroundColor: "transparent !important",
                },
                ":active": {
                  color: `${headerColor} !important`,
                  backgroundColor: "transparent !important",
                  boxShadow: "none !important",
                },
              }}
            >
              <Hamburger aria-hidden="true" />
            </Button>
          </Trigger>
        </Flex>
        <Target placement="right">
          <Box
            sx={{
              zIndex: 100,
              width: "100%",
              px: "10vw",
              py: "10px",
              backgroundColor: "neutral.0",
              overflow: "scroll",
              transitionProperty: "visibility, opacity, transform",
              transitionDuration: "500ms",
              transitionTimingFunction: "ease-in-out",
            }}
          >
            <Flex
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <HomePageLink />
              <CloseButton>
                <Button
                  aria-label="Close navigation drawer"
                  iconOnly
                  sx={{
                    backgroundColor: "transparent",
                    ...getIconColorStyles(colors.neutral["1000"]),
                  }}
                >
                  <Close aria-hidden="true" />
                </Button>
              </CloseButton>
            </Flex>
            <Flex sx={{ py: "16px", flexDirection: "column" }}>
              {NAV_LINKS.slice(1).map(({ name, children, key, to, type }) => {
                const linkStyle = createLinkStyle(key);

                return type === "external" ? (
                  <TextLink
                    key={key}
                    as="a"
                    href={to}
                    target="_blank"
                    sx={{ ...linkStyle, pt: "26px" }}
                  >
                    {name}
                  </TextLink>
                ) : (
                  <React.Fragment key={key}>
                    <Flex
                      sx={{ alignItems: "center", pt: children ? 0 : "26px" }}
                    >
                      {to && (
                        <CloseButton>
                          <TextLink sx={linkStyle} as={pageLink} to={to}>
                            {name}
                          </TextLink>
                        </CloseButton>
                      )}
                      {children && (
                        <DropdownButton
                          sx={{
                            ...linkStyle,
                            p: 0,
                          }}
                          expanded={areLinksExpanded}
                          onClick={() => setAreLinksExpanded(!areLinksExpanded)}
                        >
                          {name}
                        </DropdownButton>
                      )}
                    </Flex>
                    {children && (
                      <Flex
                        sx={{
                          opacity: areLinksExpanded ? 1 : 0,
                          maxHeight: areLinksExpanded ? "1000px" : "0",
                          overflow: "hidden",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          transition: "all .5s ease-in",
                        }}
                      >
                        {children.map(({ name, shortenedName, key, to }) => {
                          return (
                            <CloseButton key={key}>
                              <TextLink
                                sx={{
                                  ...createLinkStyle(key),
                                  fontSize: "14px",
                                  "&:first-of-type": {
                                    mt: "18px",
                                  },
                                }}
                                as={pageLink}
                                to={to}
                              >
                                {shortenedName || name}
                              </TextLink>
                            </CloseButton>
                          );
                        })}
                      </Flex>
                    )}
                  </React.Fragment>
                );
              })}
            </Flex>
          </Box>
        </Target>
      </Drawer>
    </Box>
  );
};
