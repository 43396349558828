import React, { ReactNode } from "react";
import { Box, TextLink } from "@indeed/ifl-components";

interface SkipNavContentProps {
  children: ReactNode;
  id?: string;
}

export const SkipNavLink: React.FC<{ id?: string }> = ({ id = "main" }) => {
  return (
    <TextLink
      href={`#${id}`}
      sx={{
        color: "neutral.900",
        fontWeight: 700,
        backgroundColor: "neutral.100",
        top: "2px",
        left: "50%",
        padding: "4px",
        position: "absolute",
        transform: "translateY(-120%)",
        transition: "transform 0.3s",
        zIndex: 1000,
        borderColor: "neutral.900",
        ":focus": {
          transform: "translateY(0%)",
        },
        ":visited": {
          color: "neutral.900",
        },
      }}
    >
      Skip to content
    </TextLink>
  );
};

export const SkipNavContent: React.FC<SkipNavContentProps> = ({
  children,
  id = "main",
}) => {
  return <Box id={id}>{children}</Box>;
};
