import React from "react";
import { Box, IndeedThemeProvider } from "@indeed/ifl-components";
import { SkipNavContent, SkipNavLink } from "components/skipNav";
import Footer from "components/footer";
import { DesktopNav } from "components/nav/desktopNav";
import { MobileNav } from "components/nav/mobileNav";
import { useLocationData } from "hooks/useLocationData";
import { indeeddotdesignTheme } from "styles/theme";
import "styles/site.scss";
import { Pagination } from "components/pagination";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { type, previous, next } = useLocationData();
  return (
    <IndeedThemeProvider theme={indeeddotdesignTheme}>
      <SkipNavLink />
      <MobileNav />
      <DesktopNav />
      <SkipNavContent>
        <Box
          as="main"
          sx={{
            margin: "0 10vw",
            "& > section": {
              position: "relative",
            },
          }}
        >
          {children}
        </Box>
      </SkipNavContent>
      {type === "tertiary" && <Pagination next={next!} previous={previous!} />}
      <Footer />
    </IndeedThemeProvider>
  );
};

export default Layout;
